import { Inline } from '@bedrock-layout/primitives';
import { Stack } from '@bedrock-layout/stack';
import { useAtom } from 'jotai';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import _isUndefined from 'lodash/isUndefined';
import _reduce from 'lodash/reduce';
import { useEffect, useMemo, useRef, useState } from 'react';
import { PopoverMethods, PopoverPanel, RulePopover, Typography } from 'ui';

import { DateSwitcher } from '../../../../../components/RelativeDateComponent/DateSwitcher';
import { RelativeDateFields } from '../../../../../components/RelativeDateComponent/RelativeDateFields';
import {
  extractSourceAndAttributeFromValue,
  getInitalValueifRelativeFieldActive,
  isArrayNotPresent,
} from '../../../../../utils/common';
import { EXTRACT_TOKEN_REGEX } from '../../../../../utils/regex';
import {
  CorrectValues,
  getRhsNodeTitleV2,
  getTypesToAllowForConditionNodes,
  removeCustomFunction,
} from '../../../utils/common';
import { dataSetParamsAtom } from '../../CreateRuleSheet/CreateRuleSheet';
import { ResultHeader } from '../../DecisionTable/components/ResultHeader';
import { sendEventToGTMType } from '../RuleBlock/RuleBlock';
import { simpleNodeErrors, simpleRuleNodesAtom } from '../index';
import type { ErrorByNodeId } from '../models';
import { RhsLauncher } from './RhsLauncher';
import { RhsStackContainer } from './RhsParamPopover.styled';
import { RhsInputContainer } from './RuleParamPopover.styled';

type RhsParamsPopoverProps = {
  ruleId: string;
  nodeId: string;
  handleSendEventToGTM?: (obj: sendEventToGTMType) => void;
};

export const RhsParamPopover = ({
  ruleId,
  nodeId,
  handleSendEventToGTM,
}: RhsParamsPopoverProps) => {
  const [rules, setRules] = useAtom(simpleRuleNodesAtom);
  const [panelVisible, setPanelVisible] = useState(false);
  const [isActiveDate, setIsActiveDate] = useState(true);

  const [dataset] = useAtom(dataSetParamsAtom);
  const [errorByRuleId, setErrorByRuleId] = useAtom(simpleNodeErrors);

  const dataType =
    !_isUndefined(rules[ruleId]) && !_isUndefined(rules[ruleId].dataType)
      ? rules[ruleId].dataType
      : '';
  const value =
    !_isUndefined(rules[nodeId]) && !_isUndefined(rules[nodeId].value)
      ? rules[nodeId].value
      : {};

  const selectedOperator =
    !_isUndefined(rules[ruleId]) && !_isUndefined(rules[ruleId].operator)
      ? rules[ruleId].operator
      : '';

  const title = getRhsNodeTitleV2(rules, nodeId);
  const ref = useRef<PopoverMethods>(null);

  const typesToAllow = useMemo(
    () => getTypesToAllowForConditionNodes(dataType, selectedOperator),
    [dataType, selectedOperator]
  );

  useEffect(() => {
    setIsActiveDate(!getInitalValueifRelativeFieldActive(rules[nodeId]));
  }, [JSON.stringify(rules[nodeId])]);

  const onChangeSpecial = (val: any) => {
    let newVal = val;

    try {
      const tokens = (typeof val === 'string' ? val : '').match(
        EXTRACT_TOKEN_REGEX
      );

      if (_isNil(tokens) || _isEmpty(tokens)) {
        newVal = JSON.parse(val);
      }
    } catch {}

    setRules((prev) => ({
      ...prev,
      [nodeId]: {
        ...prev[nodeId],
        nodeType: 'constant',
        value: newVal,
        dataType: 'list',
        sourceType: '',
        attribute: '',
      },
    }));
  };

  const updateDateConfiguration = (isDateActiveNow: boolean) => {
    if (isDateActiveNow) {
      const isValueOfObject =
        !_isNil(value) && typeof value === 'object' && !Array.isArray(value);

      const { source, attribute } = isValueOfObject
        ? extractSourceAndAttributeFromValue(
            (value.value ?? '') as string,
            dataset
          )
        : {
            source: undefined,
            attribute: undefined,
          };

      setRules((prev) => ({
        ...prev,
        [nodeId]: {
          ...prev[nodeId],
          sourceType: source ?? '',
          attribute: attribute ?? '',
          value:
            _isNil(source) && _isNil(attribute) && isValueObjectType
              ? value.value
              : undefined,
          nodeType:
            !_isNil(source) && !_isNil(attribute) ? 'params' : 'constant',
        },
      }));
    } else {
      const sourceType = rules[nodeId]?.sourceType;
      const attribute = rules[nodeId]?.attribute;

      let value = rules[nodeId]?.value;

      if (
        !_isNil(sourceType) &&
        !_isEmpty(sourceType) &&
        !_isNil(attribute) &&
        !_isEmpty(attribute)
      ) {
        value = `{{.${sourceType}.${attribute}}}`;
      }

      setRules((prev) => ({
        ...prev,
        [nodeId]: {
          ...prev[nodeId],
          source: undefined,
          attribute: undefined,
          nodeType: 'noCodeFunc',
          value: {
            unit: 'd',
            duration: undefined,
            value,
            subOp: 'next',
            FUNC_NAME: 'relativeDate',
          },
        },
      }));
    }

    setIsActiveDate(isDateActiveNow);
  };

  const handleRelativeFieldClick = (key: string, value: any) => {
    const currNodeValue = rules[nodeId].value;
    const relativeDateParams =
      typeof currNodeValue === 'object' && !Array.isArray(currNodeValue)
        ? currNodeValue
        : {};

    if (
      key === 'duration' &&
      typeof value === 'object' &&
      !_isNil(value) &&
      !Array.isArray(value)
    ) {
      const { sourceType, attribute } = value;
      setRules((prev) => ({
        ...prev,
        [nodeId]: {
          ...prev[nodeId],
          source: '',
          attribute: '',
          value: {
            ...relativeDateParams,
            [key]: `{{.${sourceType as string}.${attribute as string}}}`,
          },
        },
      }));
    } else {
      if (key === 'subOp' && value === 'current') {
        setRules((prev) => ({
          ...prev,
          [nodeId]: {
            ...prev[nodeId],
            source: '',
            attribute: '',
            value: {
              ...relativeDateParams,
              duration: undefined,
              [key]: value,
            },
          },
        }));
      } else {
        setRules((prev) => ({
          ...prev,
          [nodeId]: {
            ...prev[nodeId],
            source: '',
            attribute: '',
            value: {
              ...relativeDateParams,
              [key]: value,
            },
          },
        }));
      }
    }
  };

  const updateOnChangeDurationFieldError = (result: CorrectValues) => {
    if (!result.isCorrect) {
      setErrorByRuleId((prev) => ({
        ...prev,
        [nodeId]: {
          code: 500,
          message: '',
          otherFields: {
            duration: {
              code: 500,
              message:
                !_isNil(result.message) && !_isEmpty(result.message)
                  ? result.message
                  : 'Field is required',
            },
          },
        },
      }));
    } else {
      setErrorByRuleId((prev) =>
        _reduce(
          prev,
          (result: ErrorByNodeId, value, key) => {
            if (key === nodeId) {
              return result;
            }

            return {
              ...result,
              [key]: prev[key],
            };
          },
          {}
        )
      );
    }
  };

  const isRelativeFieldEnable =
    ['date', 'dateTime'].includes(dataType ?? '') && !isActiveDate;

  const isValueObjectType =
    typeof value === 'object' && !Array.isArray(value) && !_isNil(value);
  const funcName = isValueObjectType ? value.FUNC_NAME : '';

  const durationFieldError = !_isNil(
    errorByRuleId[nodeId]?.otherFields?.duration
  )
    ? errorByRuleId[nodeId]?.otherFields?.duration.message
    : null;

  return (
    <RhsStackContainer gutter={8} style={{ alignItems: 'end' }}>
      {['date', 'dateTime'].includes(dataType ?? '') && (
        <DateSwitcher
          isActiveDate={isActiveDate}
          setIsActiveDate={updateDateConfiguration}
        />
      )}
      <Stack gutter="1.2rem">
        {isRelativeFieldEnable &&
          isValueObjectType &&
          funcName === 'relativeDate' && (
            <RelativeDateFields
              dataset={dataset}
              value={value ?? {}}
              onClick={handleRelativeFieldClick}
              durationFieldError={durationFieldError}
              updateOnChangeDurationFieldError={
                updateOnChangeDurationFieldError
              }
            />
          )}
        <Inline>
          {isRelativeFieldEnable &&
            isValueObjectType &&
            funcName === 'relativeDate' && <Typography>Relative to</Typography>}
          <PopoverPanel
            trigger="click"
            placement="bottom-start"
            launcher={
              <span>
                <RhsLauncher
                  panelVisible={panelVisible}
                  text={title ?? ''}
                  nodeId={nodeId}
                  parentId={ruleId}
                  dataType={dataType ?? ''}
                  handleSendEventToGTM={handleSendEventToGTM}
                  selectedOperator={selectedOperator ?? ''}
                  isOpen={ref.current?.getTippyState()}
                  isActiveDate={isActiveDate}
                  handleHidePanel={ref.current?.hide}
                />
              </span>
            }
            ref={ref}
            padding="8px"
          >
            <RhsInputContainer
              onMouseEnter={() => setPanelVisible(true)}
              onMouseLeave={() => setPanelVisible(false)}
            >
              <RulePopover
                dataset={removeCustomFunction(dataset)}
                allowList={true}
                version="v2"
                typesToAllow={typesToAllow}
                onClick={({ value: id, key, dataType: selectedDataType }) => {
                  if (typeof handleSendEventToGTM === 'function') {
                    handleSendEventToGTM({
                      action: 'selection',
                      element: 'rhs_value',
                      actionName: dataType ?? '',
                    });
                  }

                  if (key === 'custom') {
                    // TODO: do something here
                  } else {
                    if (!_isNil(errorByRuleId[nodeId])) {
                      setErrorByRuleId((prev) =>
                        _reduce(
                          prev,
                          (result: ErrorByNodeId, value, key) => {
                            if (key === nodeId) {
                              return result;
                            }

                            return {
                              ...result,
                              [key]: prev[key],
                            };
                          },
                          {}
                        )
                      );
                    }

                    let fieldsToUpdate: Record<string, any> = {};

                    if (isActiveDate) {
                      fieldsToUpdate = {
                        ...fieldsToUpdate,
                        sourceType: key,
                        attribute: id,
                      };
                    } else {
                      const currNodeValue = rules[nodeId].value;
                      const relativeDateParams =
                        typeof currNodeValue === 'object' &&
                        !Array.isArray(currNodeValue)
                          ? currNodeValue
                          : {};

                      fieldsToUpdate = {
                        value: {
                          ...relativeDateParams,
                          value: `{{.${key}.${id}}}`,
                        },
                      };
                    }

                    setRules((prev) => ({
                      ...prev,
                      [nodeId]: {
                        nodeType: isActiveDate ? 'params' : 'noCodeFunc',
                        parent: ruleId,
                        siblingIndex: rules[ruleId].siblingIndex,
                        dataType: selectedDataType,
                        ...fieldsToUpdate,
                      },
                    }));
                  }

                  ref.current?.hide();
                }}
                header={
                  typesToAllow.includes('list') ? (
                    <ResultHeader
                      dataSet={removeCustomFunction(dataset)}
                      resIndex={0}
                      onChangeSpecial={onChangeSpecial}
                      dataType={'list'}
                      overrideValue={rules[nodeId]?.value}
                      version="v2"
                      isAdd={
                        // eslint-disable-next-line
                        !!rules[nodeId].attribute ||
                        isArrayNotPresent(rules[nodeId]?.value)
                      }
                    />
                  ) : undefined
                }
              />
            </RhsInputContainer>
          </PopoverPanel>
        </Inline>
      </Stack>
    </RhsStackContainer>
  );
};
