import { Typography } from 'ui';

import { Container } from './DateSwitcher.styled';

type DateSwitcherProps = {
  isActiveDate: boolean;
  setIsActiveDate: (data: boolean) => void;
  position?: string;
};

export const DateSwitcher = ({
  isActiveDate,
  setIsActiveDate,
  position = 'absolute',
}: DateSwitcherProps) => {
  return (
    <Container
      position={position}
      gutter="0.5rem"
      onClick={() => setIsActiveDate(!isActiveDate)}
    >
      <Typography name="heading6" className={isActiveDate ? 'active-text' : ''}>
        Date
      </Typography>
      <Typography
        name="heading6"
        className={!isActiveDate ? 'active-text' : ''}
      >
        Relative
      </Typography>
    </Container>
  );
};
